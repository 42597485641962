var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"grid-zone",attrs:{"id":"manuel_numerique_overview"}},[_c('div',{attrs:{"id":"block_left"}},[_c('figure',{attrs:{"id":"illustration"}},[_c('img',{attrs:{"src":_vm.product.url_couverture
          || require(`@lde/core_lde_vue/dist/assets/media/missing_img/no_img_numerique.svg`),"alt":_vm.$t('general.alt-image-produit')},on:{"error":function($event){_vm.product.url_couverture = null}}}),(_vm.product.nouveaute)?_c('Badge',{attrs:{"variant":"new"}}):_vm._e()],1),_c('br'),_c('ButtonClassic',{attrs:{"balise-type":"a","variant":"ghost","label":_vm.$t('general.feuilleter'),"color":"primary","icon":"right","size":"large","ext-link":_vm.product.url_demonstration,"disabled":!_vm.product.url_demonstration},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('UilEye')]},proxy:true}])})],1),_c('div',{attrs:{"id":"block_right"}},[_c('h1',{staticClass:"s3 bold",attrs:{"id":"product_title"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"text-regular",attrs:{"id":"disponibilite"}},[_c('span',{attrs:{"id":"ean"}},[_vm._v(_vm._s(_vm.$t('produit.ean'))+" "+_vm._s(_vm.product.ean))]),_c('span',{staticClass:"circle",class:_vm.couleurDispo}),_c('span',{class:`text-${_vm.couleurDispo}`},[_vm._v(" "+_vm._s(_vm.libelleDispo)+" ")])]),_c('div',{attrs:{"id":"supports_minima"}},[_c('ManuelNumeriqueSupports',{attrs:{"product":_vm.product}}),(_vm.product.offre_defaut?.quantite_min > 1)?_c('p',{staticClass:"minima-text text-small italic"},[_vm._v(" "+_vm._s(_vm.$t("produit.minima-a-x", { minima: _vm.product.offre_defaut?.quantite_min, }))+" "),_c('UilQuestionCircle',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('produit.sous-reserve-conditions'),
            theme: 'blue',
          }),expression:"{\n            content: $t('produit.sous-reserve-conditions'),\n            theme: 'blue',\n          }"}],attrs:{"size":"16"}})],1):_vm._e()],1),_c('div',{attrs:{"id":"choice_add_to_list"}},[(_vm.product.offres.length > 1)?_c('div',{staticClass:"flex-vcenter multiple-offer"},[(_vm.product.offre_defaut)?_c('div',{staticClass:"prices text-medium text-right"},[(_vm.product.offre_defaut.prix_editeur !== _vm.product.offre_defaut.prix_ht)?_c('strong',{staticClass:"all-taxes bold",domProps:{"innerHTML":_vm._s(_vm.$t('prix.a-partir-de-x-ttc', { prix: _vm.moneyFilter(_vm.product.offre_defaut.prix_editeur), }))}}):_vm._e(),_c('strong',{staticClass:"duty-free",domProps:{"innerHTML":_vm._s(_vm.$t('prix.ht', { prix: _vm.moneyFilter(_vm.product.offre_defaut.prix_ht), }))}})]):_vm._e(),_c('ButtonClassic',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll",value:({
            duration: 1000,
            offset: -110,
            container: '#scroll_content',
          }),expression:"{\n            duration: 1000,\n            offset: -110,\n            container: '#scroll_content',\n          }"}],attrs:{"balise-type":"a","variant":"solid","label":_vm.$t('produit.selectionner-une-offre'),"color":"secondary","icon":"right","size":"large","href":"#product_offers"},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('UilArrowDown')]},proxy:true}],null,false,145449941)})],1):[(_vm.product.offre_defaut)?_c('div',{staticClass:"one-offer"},[_c('SelectClassic',{attrs:{"options":[],"use-placeholder":false,"search-bar":false,"disabled":""},scopedSlots:_vm._u([{key:"before-option",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("produit.votre-offre"))+" ")]},proxy:true}],null,false,3488361654),model:{value:(_vm.defaultOffer),callback:function ($$v) {_vm.defaultOffer=$$v},expression:"defaultOffer"}}),(!_vm.product.offre_defaut.prescripteur)?[(_vm.product.offre_defaut)?_c('div',{staticClass:"prices text-medium"},[(_vm.product.offre_defaut.prix_editeur !== _vm.product.offre_defaut.prix_ht)?_c('strong',{staticClass:"all-taxes",domProps:{"innerHTML":_vm._s(_vm.$t('prix.ttc', { prix: _vm.moneyFilter(_vm.product.offre_defaut.prix_editeur), }))}}):_vm._e(),_c('span',{staticClass:"duty-free",domProps:{"innerHTML":_vm._s(_vm.$t('prix.ht', { prix: _vm.moneyFilter(_vm.product.offre_defaut.prix_ht), }))}})]):_vm._e(),_c('InputQuantity',{attrs:{"status":_vm.product.offre_defaut.quantite < _vm.product.offre_defaut?.quantite_min ? 'warning' : 'default',"disabled":!_vm.computedIsProductAvailable,"min":1},model:{value:(_vm.chosenQuantity),callback:function ($$v) {_vm.chosenQuantity=$$v},expression:"chosenQuantity"}})]:_c('p',{staticClass:"prices text-medium bold"},[_vm._v(" "+_vm._s(_vm.$t("offre.offre-offerte-editeur"))+" ")])],2):_vm._e(),_c('footer',[_c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll",value:({
              duration: 1000,
              offset: -110,
              container: '#scroll_content',
            }),expression:"{\n              duration: 1000,\n              offset: -110,\n              container: '#scroll_content',\n            }"}],staticClass:"text-medium underline",attrs:{"id":"detail_offer","href":"#product_offers"}},[_vm._v(" "+_vm._s(_vm.$t("produit.detail-offres"))+" ")]),(_vm.product.offre_defaut
              && !_vm.product.offre_defaut.prescripteur
              && _vm.product.offre_defaut.prix_editeur !== _vm.product.offre_defaut.prix_ht)?_c('p',{staticClass:"text-small italic"},[_vm._v(" "+_vm._s(_vm.$t("produit.prix-ttc-titre-indicatif"))+" ")]):_vm._e()])]],2),_c('p',{attrs:{"id":"warning"}},[_c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll",value:({
          duration: 1000,
          offset: -110,
          container: '#scroll_content',
        }),expression:"{\n          duration: 1000,\n          offset: -110,\n          container: '#scroll_content',\n        }"}],staticClass:"text-small italic",attrs:{"href":"#product_required"}},[_vm._v(" "+_vm._s(_vm.$t("produit.necessaire-verifier-materiel"))+" "),_c('UisExclamationTriangle',{attrs:{"size":"16"}})],1)]),_c('div',{attrs:{"id":"zone_select_button"}},[(_vm.product.offres.length === 1)?_c('p',{staticClass:"text-medium"},[_vm._v(" "+_vm._s(_vm.$t("general.ajouter-produit"))+" ")]):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('produit.article-n-est-pas-disponible'),
          disabled: _vm.computedIsProductAvailable,
        }),expression:"{\n          content: $t('produit.article-n-est-pas-disponible'),\n          disabled: computedIsProductAvailable,\n        }"}],staticClass:"zone-liste-panier"},[(_vm.product.offres.length === 1)?_c('ButtonClassic',{attrs:{"variant":"special","label":_vm.$t('liste.a-une-liste'),"color":"primary","icon":"right","size":"medium","disabled":_vm.addToListDisabled || _vm.isImpersonating || !_vm.chosenQuantity || _vm.isFetchingActiveLists},on:{"click":function($event){!_vm.addToListDisabled ?
            _vm.$emit('add-to-liste', { id: _vm.product.offre_defaut.id, quantite: _vm.chosenQuantity, }) : ''}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [(_vm.isFetchingActiveLists)?_c('InfiniteLoader'):_c('UilBookmark')]},proxy:true}],null,false,65223215)}):_vm._e(),(_vm.product.offres.length === 1)?_c('ButtonClassic',{attrs:{"variant":"solid","label":_vm.$t('panier.au-panier'),"color":"secondary","icon":"right","size":"large","disabled":_vm.addToListDisabled || _vm.isImpersonating || !_vm.chosenQuantity},on:{"click":function($event){!_vm.addToListDisabled ?
            _vm.$store.dispatch(
              'addOffersToPanier',
              { lignes: [{ id_offre: _vm.product.offre_defaut.id, quantite: _vm.chosenQuantity, }, ], })
            : ''}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('IconPanier')]},proxy:true}],null,false,893002052)}):_vm._e()],1),(_vm.offresAlreadyInList.length)?_c('div',{attrs:{"id":"selected_licences"}},[_c('span',[_c('UisBookmark',{attrs:{"size":"16"}})],1),_c('ul',_vm._l((_vm.offresAlreadyInList),function(ligne){return _c('li',{key:ligne.id,staticClass:"text-small italic"},[_vm._v(" "+_vm._s(_vm.adoptantTexte(ligne))+" ")])}),0)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }