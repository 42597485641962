<template>
  <section
    id="manuel_numerique_overview"
    class="grid-zone"
  >
    <div id="block_left">
      <figure id="illustration">
        <img
          :src="product.url_couverture
            || require(`@lde/core_lde_vue/dist/assets/media/missing_img/no_img_numerique.svg`)"
          :alt="$t('general.alt-image-produit')"
          @error="product.url_couverture = null"
        />
        <Badge
          v-if="product.nouveaute"
          variant="new"
        />
      </figure>
      <br />
      <ButtonClassic
        balise-type="a"
        variant="ghost"
        :label="$t('general.feuilleter')"
        color="primary"
        icon="right"
        size="large"
        :ext-link="product.url_demonstration"
        :disabled="!product.url_demonstration"
      >
        <template #right-icon>
          <UilEye />
        </template>
      </ButtonClassic>
    </div>

    <div id="block_right">
      <h1
        id="product_title"
        class="s3 bold"
      >
        {{ title }}
      </h1>
      <p
        id="disponibilite"
        class="text-regular"
      >
        <span id="ean">{{ $t('produit.ean') }} {{ product.ean }}</span>
        <span
          class="circle"
          :class="couleurDispo"
        />
        <span :class="`text-${couleurDispo}`">
          {{ libelleDispo }}
        </span>
      </p>
      <div id="supports_minima">
        <ManuelNumeriqueSupports :product="product" />
        <p
          v-if="product.offre_defaut?.quantite_min > 1"
          class="minima-text text-small italic"
        >
          {{ $t("produit.minima-a-x", { minima: product.offre_defaut?.quantite_min, }) }}
          <UilQuestionCircle
            v-tooltip="{
              content: $t('produit.sous-reserve-conditions'),
              theme: 'blue',
            }"
            size="16"
          />
        </p>
      </div>
      <div id="choice_add_to_list">
        <div
          v-if="product.offres.length > 1"
          class="flex-vcenter multiple-offer"
        >
          <div
            v-if="product.offre_defaut"
            class="prices text-medium text-right"
          >
            <strong
              v-if="product.offre_defaut.prix_editeur !== product.offre_defaut.prix_ht"
              class="all-taxes bold"
              v-html="$t('prix.a-partir-de-x-ttc', { prix: moneyFilter(product.offre_defaut.prix_editeur), })"
            />
            <strong
              class="duty-free"
              v-html="$t('prix.ht', { prix: moneyFilter(product.offre_defaut.prix_ht), })"
            />
          </div>
          <ButtonClassic
            v-smooth-scroll="{
              duration: 1000,
              offset: -110,
              container: '#scroll_content',
            }"
            balise-type="a"
            variant="solid"
            :label="$t('produit.selectionner-une-offre')"
            color="secondary"
            icon="right"
            size="large"
            href="#product_offers"
          >
            <template #right-icon>
              <UilArrowDown />
            </template>
          </ButtonClassic>
        </div>
        <template v-else>
          <div
            v-if="product.offre_defaut"
            class="one-offer"
          >
            <SelectClassic
              v-model="defaultOffer"
              :options="[]"
              :use-placeholder="false"
              :search-bar="false"
              disabled
            >
              <template #before-option>
                {{ $t("produit.votre-offre") }}
              </template>
            </SelectClassic>
            <template v-if="!product.offre_defaut.prescripteur">
              <div
                v-if="product.offre_defaut"
                class="prices text-medium"
              >
                <strong
                  v-if="product.offre_defaut.prix_editeur !== product.offre_defaut.prix_ht"
                  class="all-taxes"
                  v-html="$t('prix.ttc', { prix: moneyFilter(product.offre_defaut.prix_editeur), })"
                />
                <span
                  class="duty-free"
                  v-html="$t('prix.ht', { prix: moneyFilter(product.offre_defaut.prix_ht), })"
                />
              </div>
              <InputQuantity
                v-model="chosenQuantity"
                :status="product.offre_defaut.quantite < product.offre_defaut?.quantite_min ? 'warning' : 'default'"
                :disabled="!computedIsProductAvailable"
                :min="1"
              />
            </template>
            <p
              v-else
              class="prices text-medium bold"
            >
              {{ $t("offre.offre-offerte-editeur") }}
            </p>
          </div>
          <footer>
            <a
              id="detail_offer"
              v-smooth-scroll="{
                duration: 1000,
                offset: -110,
                container: '#scroll_content',
              }"
              class="text-medium underline"
              href="#product_offers"
            >
              {{ $t("produit.detail-offres") }}
            </a>
            <p
              v-if="product.offre_defaut
                && !product.offre_defaut.prescripteur
                && product.offre_defaut.prix_editeur !== product.offre_defaut.prix_ht"
              class="text-small italic"
            >
              {{ $t("produit.prix-ttc-titre-indicatif") }}
            </p>
          </footer>
        </template>
      </div>

      <p id="warning">
        <a
          v-smooth-scroll="{
            duration: 1000,
            offset: -110,
            container: '#scroll_content',
          }"
          href="#product_required"
          class="text-small italic"
        >
          {{ $t("produit.necessaire-verifier-materiel") }}
          <UisExclamationTriangle size="16" />
        </a>
      </p>
      <div id="zone_select_button">
        <p
          v-if="product.offres.length === 1"
          class="text-medium"
        >
          {{ $t("general.ajouter-produit") }}
        </p>
        <div
          v-tooltip="{
            content: $t('produit.article-n-est-pas-disponible'),
            disabled: computedIsProductAvailable,
          }"
          class="zone-liste-panier"
        >
          <ButtonClassic
            v-if="product.offres.length === 1"
            variant="special"
            :label="$t('liste.a-une-liste')"
            color="primary"
            icon="right"
            size="medium"
            :disabled="addToListDisabled || isImpersonating || !chosenQuantity || isFetchingActiveLists"
            @click="!addToListDisabled ?
              $emit('add-to-liste', { id: product.offre_defaut.id, quantite: chosenQuantity, }) : ''
            "
          >
            <template #right-icon>
              <InfiniteLoader v-if="isFetchingActiveLists" />
              <UilBookmark v-else />
            </template>
          </ButtonClassic>
          <ButtonClassic
            v-if="product.offres.length === 1"
            variant="solid"
            :label="$t('panier.au-panier')"
            color="secondary"
            icon="right"
            size="large"
            :disabled="addToListDisabled || isImpersonating || !chosenQuantity"
            @click="!addToListDisabled ?
              $store.dispatch(
                'addOffersToPanier',
                { lignes: [{ id_offre: product.offre_defaut.id, quantite: chosenQuantity, }, ], })
              : ''
            "
          >
            <template #right-icon>
              <IconPanier />
            </template>
          </ButtonClassic>
        </div>
        <div
          v-if="offresAlreadyInList.length"
          id="selected_licences"
        >
          <span>
            <UisBookmark size="16" />
          </span>
          <ul>
            <li
              v-for="ligne in offresAlreadyInList"
              :key="ligne.id"
              class="text-small italic"
            >
              {{ adoptantTexte(ligne) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  ButtonClassic,
  InputQuantity,
  SelectClassic,
  moneyFilter,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import Badge from "@/components/Badge.vue";
import ManuelNumeriqueSupports from "@/components/manuels_numeriques/ManuelNumeriqueSupports.vue";

import {
  UilEye,
  UilArrowDown,
  UilQuestionCircle,
  UilBookmark,
} from "@iconscout/vue-unicons";
import { UisExclamationTriangle, UisBookmark } from "@iconscout/vue-unicons-solid";
import IconPanier from "@/components/icons/IconPanier.vue";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { mapGetters } from "vuex";

/**
 * Affiche les informations de base du produit avec les licences disponibles.
 */
export default {
  name: "ManuelNumeriqueOverview",
  components: {
    ButtonClassic,
    InputQuantity,
    SelectClassic,
    Badge,
    ManuelNumeriqueSupports,
    UilEye,
    UilArrowDown,
    UisExclamationTriangle,
    UilBookmark,
    UisBookmark,
    UilQuestionCircle,
    IconPanier,
    InfiniteLoader,
  },
  mixins: [mixinDisponibilite],
  props: {
    /**
     * Produit affiché.
     */
    product: {
      type: Object,
      required: true,
    },
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton d'enregistrement.
     * @param {Object} offer Offre associée à la quantité.
     */
    "add-to-liste",
  ],
  data() {
    return {
      chosenQuantity: 1,
    };
  },
  computed: {
    ...mapGetters(["panier", "hasPerm", "compatibleAdoptant", "isFetchingActiveLists"]),
    title() {
      return this.product.libelle;
    },
    offresAlreadyInList() {
      if (this.panier?.lignes) {
        const offersIds = this.product.offres.map((offre) => offre.id);
        return this.panier.lignes.filter((ligne) => offersIds.includes(ligne.offre.id));
      }
      return [];
    },
    addToListDisabled() {
      return !this.hasPerm("add_lignedeliste")
        || this.product.offre_defaut?.prescripteur
        || !this.computedIsProductAvailable;
    },
    defaultOffer() {
      const offre = this.product.offre_defaut;
      const { adoptant, id, duree_verbose: duree } = offre;
      let offreDefaut = null;

      offreDefaut = {
        ...offre,
        value: id,
        label: this.$t("produit.licence-x", { duree }),
      };

      if (!this.compatibleAdoptant && this.product.categorie !== 21) {
        offreDefaut.label = adoptant
          ? this.$t("produit.licence-x-adoptant", { duree })
          : this.$t("produit.licence-x-non-adoptant", { duree });
      }

      return offreDefaut;
    },
  },
  mounted() {
    this.chosenQuantity = this.product.offre_defaut?.quantite_min;
  },
  methods: {
    /**
     * Change la quantité.
     * @param {Object} ligne Information de la ligne.
     * @param {Number} val Nouvelle quantité.
     */
    changeQuantity(offer, val) {
      this.$set(offer, "quantite", val);
    },
    /**
     * Récupère le texte pour l'offre adoptant ou non.
     * @param {Object} ligne Donnée qui contient l'offre et la quantité.
     */
    adoptantTexte({ offre, quantite }) {
      if (!this.compatibleAdoptant && this.product.categorie !== 21) {
        return offre.adoptant ? this.$tc(
          "produit.x-licence-selectionnee-adoptant",
          quantite,
          {
            quantite,
            duree: offre.duree_verbose,
          },
        ) : this.$tc(
          "produit.x-licence-selectionnee-non-adoptant",
          quantite,
          {
            quantite,
            duree: offre.duree_verbose,
          },
        );
      }
      return this.$tc(
        "produit.x-licence-selectionnee",
        quantite,
        {
          quantite,
          duree: offre.duree_verbose,
        },
      );
    },
    moneyFilter,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/manuels_numeriques/manuel_numerique_overview.scss";
</style>
